Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "posts/posts";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";

exports.Category = "Category";
exports.Description = "Description";
exports.Price = "Price";
exports.ProductName = "Product Name";
exports.CreatePost = "Create Post";
exports.UpdatePost = "Update Post";
exports.Error = "Error";
exports.FieldsErrorMassage = "Please enter all mandatory fields";
exports.Warning = "Warning";
exports.MessageForDelete = "Are you sure for delete this post?";
exports.PostName = "Post Name";
exports.Cost = "Cost";
exports.PostID = "Post ID";
exports.Delete = "Delete";
exports.Edit = "Edit";
exports.NewPost = "Add new Post";
exports.PostUpdated = "Post is updated!";
exports.PostCreated = "Post is created!";



// Content types
exports.getApiContentType = "application/json";

// Method types
exports.getApiMethod = "GET";
exports.httpPostMethod = "POST";


// API Endpoints
exports.getCategoriesEndpoint = "bx_block_categories/categories";
exports.createPostEndpoint = "bx_block_posts/posts";

// Constants
exports.fetchErrorMsg = "Something went wrong!"
exports.titlePostCreation = "Create a new post";
exports.titleSelectCategories = "SELECT CATEGORIES";
exports.successMsg = "Post created!";
exports.archive = "archive";
exports.invalidFileTypeMsg = "Only images and videos are permitted.";
exports.duplicateFileMsg = "Duplicate files cannot be uploaded.";
exports.maxFileAllowed = 5;
exports.maxFileValidation = "You can only upload up to 5 files.";
exports.landingPage = "LandingPage";
exports.title = "title";
exports.description = "description";
exports.titleMsg = "Please enter post title";
exports.descriptionMsg = "Please enter post description";
exports.mediaMsg = "Please upload post media";
exports.privacyMsg = "Please select a privacy option";
exports.archiveMsg = "Please select archive option";
exports.uploadBtnTxt = "Add images or videos";
exports.disclaimer = "Disclaimer : Archived posts will be a fee, temporary will be X days, etc.";
exports.postTxt = "Post";
exports.saveDraft = "Save Draft";
exports.MAX_FILE_SIZE = 4 * 1024 * 1024;

// Customizable Area End