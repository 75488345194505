import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Alert } from "react-native";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { OptionTypeBase, ValueType } from "react-select";

// Customizable Area Start
import { callApi } from "../../../components/src/Utilities";
import { toast } from "react-hot-toast";
import { getStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";



interface ICategoryAttributes {
  id: number;
  name: string;
  category_image: string;
  light_icon: string;
  dark_icon: string;
}

interface ICategory {
  id: string;
  type: string;
  attributes: ICategoryAttributes;
}

interface ICategoryResponse {
  data: ICategory[];
}

interface IPostCreationResponse {
  meta: string;
}



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PostData: [];
  token: string;
  name: string;
  description: string;
  price: string;
  currency: string;
  category_id: string;
  image: string | undefined;
  uploadedImages: [];
  AllCategory: [];
  id: string;
  refresh: boolean;
  file: string;
  profileImageData: { data: string | null | undefined, content_type: string, filename: string };
  selectedCategory: ValueType<OptionTypeBase, false>;
  allCategories: { value: string, label: string }[];
  selectedPrivacyOption: string;
  selectedArchiveOption: string;
  categoryData: ICategory[];
  selectedCategoryId: number;
  postDescription: string;
  postTitle: string;
  isArchiveModalOpen: boolean;
  selectedFiles: File[];
  isLoading: boolean;
  isCreationResModalOpen: boolean;
  isUploadProgress: boolean;
  filePreviews: string[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostItemCallId: string = "";
  apiGetCategoryCallID: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: string = "";;
  addpostApiCallId: string = "";;
  updatePostApiCallId: string = "";
  fileInputRef = createRef<HTMLInputElement>();
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: "",
      PostData: [],
      name: "",
      description: "",
      price: "",
      currency: "$",
      category_id: "",
      image: "",
      id: "",
      uploadedImages: [],
      AllCategory: [],
      file: "",
      refresh: false,
      profileImageData: { data: null, content_type: "", filename: "" },
      selectedCategory: {},
      allCategories: [],
      selectedPrivacyOption: "",
      selectedArchiveOption: "",
      categoryData: [],
      selectedCategoryId: 5,
      postDescription: "",
      postTitle: "",
      isArchiveModalOpen: false,
      selectedFiles: [],
      isLoading: false,
      isCreationResModalOpen: false,
      isUploadProgress: false,
      filePreviews: []
    };
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getCategories();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.setState({
      isLoading: false
    })

    if (errorReponse || responseJson?.errors || responseJson?.error) {
      this.apiFailureCallBacks();
    }

    else if (responseJson && !responseJson?.errors) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }

  }

  createPostCreation() {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert(configJSON.Error, configJSON.FieldsErrorMassage);
    } else {
      this.AddPostCreation();
    }
  }

  goToItemDetails(item: { attributes: { name: string, id: string } } | null, isEdit: boolean) {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      isEdit ? "PostCreation" : "PostDetails"
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.PostDetailDataMessage), item);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }

  editNavigation = (item: { attributes: { name: string, id: string } }) => {
    this.goToItemDetails(item, true);
  };

  navigateToDetails = (item: { attributes: { name: string, id: string } }) => {
    this.goToItemDetails(item, false);
  };

  AddPostCreation(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const attrs = {
      name: this.state.name,
      description: this.state.description,
      body: this.state.description,
      price: this.state.price,
      currency: "$",
      category_id: this.state.category_id,
      sub_category_id: 1,
      image: this.state.profileImageData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addpostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllCategory() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoryCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCatergoryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPostData(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateCreatePostData(recordId: string) {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert(configJSON.Error, configJSON.FieldsErrorMassage);
      return false;
    } else {
      const header = {
        "Content-Type": configJSON.postContentType,
        token: this.state.token
      };
      const attrs = {
        name: this.state.name,
        description: this.state.description,
        body: this.state.description,
        sub_category_id: 1,
        price: this.state.price,
        currency: "$",
        category_id: this.state.category_id,
        image: this.state.profileImageData
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updatePostApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postGetUrl + "/" + `${recordId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(attrs)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchPostAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  deleteRecord(recordId: string) {
    Alert.alert(
      configJSON.Warning,
      configJSON.MessageForDelete,
      [
        { text: "No", onPress: () => { }, style: "cancel" },
        {
          text: "Yes",
          onPress: () => {
            this.setState({ refresh: true });
            this.delete(recordId);
          }
        }
      ],
      { cancelable: false }
    );
  }

  delete(recordId: string) {
    this.setState({ refresh: true });
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl + "/" + `${recordId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getPostData();
    return true;
  }

  txtInputProductNameProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ name: text });
      this.txtInputProductNameProps.value = text;
    }
  };

  txtInputProductDiscripationProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ description: text });
      this.txtInputProductDiscripationProps.value = text;
    }
  };

  txtInputProductPriceProps = {
    value: '',
    onChangeText: (text: string) => {
      this.setState({ price: text });
      this.txtInputProductPriceProps.value = text;
    }
  };

  chooseImage = () => {
    this.showAlert("Error", "Image Picker Not Implemented");
  };

  handleCatChangeChange = (selectedOption: ValueType<OptionTypeBase, false>) => {
    this.setState({
      selectedCategory: selectedOption,
      category_id: selectedOption?.value
    });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const { name } = event.target;

    type === "privacy" ?
      this.setState({
        selectedPrivacyOption: name,
      }) :
      this.setState({
        selectedArchiveOption: name,
      })
  };


  getCategoriesId: string = "";
  creatPostApiCallId: string = "";

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ICategoryResponse & IPostCreationResponse) => {
    if (apiRequestCallId === this.getCategoriesId) {
      this.handleCategorySuccessCallback(responseJson)
    }
    else if (apiRequestCallId === this.creatPostApiCallId){
      this.handlePostCreationSuccessCallback(responseJson);
    }
  }

  apiFailureCallBacks = () => {
    toast.error(configJSON.fetchErrorMsg);
  }

  handleCategorySuccessCallback = (responseJson: ICategoryResponse) => {

    this.setState({
      categoryData: responseJson?.data
    });

  }

  handlePostCreationSuccessCallback = (responseJson: IPostCreationResponse) => {
    if(responseJson?.meta === configJSON.successMsg){
      this.setState({
        isCreationResModalOpen: true
      })
    }
  }

  setSelectedFiles = (mediaData: File[]) => {
    const filePreviews: string[] = [];

    mediaData.forEach((file) => {
      const previewUrl = URL.createObjectURL(file);
      filePreviews.push(previewUrl);
    });

    this.setState({
      filePreviews: filePreviews,
      selectedFiles: mediaData
    });

    this.setLoader(false);

  }
  
  setLoader = (toggle: boolean) => {
    this.setState({
      isUploadProgress: toggle
    })
  }


  getCategories = async () => {

    const authToken = await getStorageData("token");

    this.getCategoriesId = callApi({
      contentType: configJSON.getApiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${(configJSON.getCategoriesEndpoint as string)}`,
      headers: { token: authToken }
    }, runEngine);

  }


  createPost = (postAction: string) => {
    if (this.state.selectedArchiveOption === configJSON.archive){
      this.toggleArchiveModal(true);
    }
    else {
      this.createPostApiCall(postAction);
    }
  }


  handleDeleteFile = (file: File, previewString: string) => {
    const updatedFiles = this.state.selectedFiles.filter(
      (selectedFile) => selectedFile.name !== file.name
    );

    const updatedPreviews = this.state.filePreviews.filter(
      (preview) => preview !== previewString
    );

    this.setState({ selectedFiles: updatedFiles, filePreviews: updatedPreviews });
  }


  validatePostData = () => {

    
    const { postDescription, postTitle, selectedFiles, selectedPrivacyOption, selectedArchiveOption } = this.state;

    if (!postTitle){
      toast.error(configJSON.titleMsg);
      return false;
    }

    if (!postDescription){
      toast.error(configJSON.descriptionMsg);
      return false;
    }

    if (selectedFiles.length === 0){
      toast.error(configJSON.mediaMsg);
      return false;
    }

    if (!selectedPrivacyOption){
      toast.error(configJSON.privacyMsg);
      return false;
    }

    if (!selectedArchiveOption){
      toast.error(configJSON.archiveMsg);
      return false;
    }

    return true;
  }



  createPostApiCall = async ( postAction: string ) => {

    if (this.validatePostData()){
      this.setState({
        isLoading: true
      })
  
      const { postDescription, selectedCategoryId, postTitle, selectedFiles } = this.state;
      const authToken = await getStorageData("token");
  
      const formData = new FormData();
      const header = { token: authToken };
      const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.creatPostApiCallId = reqMessage.messageId;
  
      formData.append('description', postDescription);
      formData.append('category_id', JSON.stringify(selectedCategoryId));
      formData.append('account_privacy', this.state.selectedPrivacyOption);
      formData.append('post_archive', this.state.selectedArchiveOption);
      formData.append('post_action', postAction);
      formData.append('body', postTitle);
  
      selectedFiles.forEach((file) => {
        formData.append("images[]", file);
      });
  
      reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
      reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createPostEndpoint);
      reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
      runEngine.sendMessage(reqMessage.id, reqMessage);
    }

  }

  handleTextFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, fieldName: string) => {

    const { value } = event.target;

    if (fieldName === configJSON.title){
      this.setState({
        postTitle: value
      })
    }
    else {
      this.setState({
        postDescription: value
      })
    }
  }


  closeResModal = () => {
    this.setState({
      isCreationResModalOpen: false
    })
    this.navigateToPage(configJSON.landingPage)
  }

  navigateToPage = ( page: string ) => {

    const navigateMsg = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigateMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      page
    );
    navigateMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    this.send(navigateMsg);

  };

  handleButtonClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  handleCategorySelect = (id: number) => {

    this.setState({
      selectedCategoryId: id
    })

  }

  toggleArchiveModal = (toggle: boolean) => {
    this.setState({
      isArchiveModalOpen: toggle
    })
  }

  // Customizable Area End
}
