import React from "react";
import CategoriessubcategoriesController, {
  Props
} from "./CategoriessubcategoriesController";
import { Typography } from "@material-ui/core";

export default class Categoriessubcategories extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <>
        {
          this.state.categoriesArray.map((category) => {
            return (
              <Typography style={{ display: "none" }}>{category?.attributes?.name}</Typography>
            )
          })
        }
      </>
      
    );
  }
}
// Customizable Area Start
// Customizable Area End